.slick-slide>div {
    margin: 20px 10px;
}

.slick-list {
    margin: 0 -10px 30px 0;
}


/* Custom Slick Dots */

.ft-slick__dots--custom {
    height: 10px;
    width: 15px;
    background-color: #003C9D80;
    border-radius: 4px;
    position: relative;
}

.slick-dots li {
    width: 14px;
    margin: -15px 10px;
}

.slick-dots .slick-active {
    width: 56px;
}

.slick-dots .slick-active .ft-slick__dots--custom {
    width: 50px;
    top: 0px;
    overflow: hidden;
    color: #003C9D;
    background: #003C9D;
    display: inline-block;
    position: absolute;
    left: 0;
    border-radius: 10px;

}


.slick-slide {
    opacity: .3;
    transition: opacity .5s ease-in-out;
}

.slick-current {
    opacity: 1;
    transition: opacity .5s ease-in-out;
}

.slick-active {
    opacity: 1;
    transition: opacity .5s ease-in-out;
}

@media only screen and (max-width: 1200px) {
    .slick-list {
        margin-bottom: 10px;
    }
}

@media only screen and (max-width: 768px) {
    .ft-slick__dots--custom {
        width: 8px;
        height: 8px;
    }

    .slick-dots .slick-active .ft-slick__dots--custom {
        width: 25px;
    }

    .slick-dots li {
        width: 14px;
        margin: 0 5px;
    }

    .slick-dots .slick-active {
        width: 0;
    }

    .slick-slide>div {
        margin: 0px 0px;
    }

    .slick-dots {
        bottom: -58px;
    }

    .slick-list {
        margin: 0 0 0 0;
    }

    /* .slick-slider .slick-track{
        margin: 0 40px!important;
    } */



}