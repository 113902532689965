@tailwind base;
@tailwind components;
@tailwind utilities;
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap");

.orangeButton {
  background: #f37021;
  color: #ffffff;
  border-radius: 5px;
  padding: 12px 24px;
  font-size: 18px;
  font-weight: 500;
  cursor: pointer;
}

.whiteButton {
  background: #ffffff;
  color: #f37021;
  border-radius: 5px;
  padding: 12px 24px;
  font-size: 18px;
  font-weight: 500;
  cursor: pointer;
}

.orangeHoverButton {
  background: #f37021;
  color: #ffffff;
  border-radius: 5px;
  padding: 8px 16px;
  font-size: 18px;
  font-weight: 500;
  cursor: pointer;
}

.orangeHoverButton:hover {
  background: #003c9d;
}

.orangeOutlineButton {
  background: #ffffff;
  color: #f37021;
  border-radius: 5px;
  padding: 8px 16px;
  font-size: 18px;
  font-weight: 500;
  border: 1px solid #f37021;
  cursor: pointer;
}

.orangeOutlineButton:hover {
  border: 1px solid #003c9d;
  color: #003c9d;
}

.cardBorder {
  background: linear-gradient(#ffffff, #ffffff) padding-box,
    linear-gradient(180deg, #003c9d 0%, #f37021 100%) border-box;
  border: 1px solid transparent;
}

::-webkit-scrollbar {
  width: 2px;
  height: 7px;
}

::-webkit-scrollbar-thumb {
  background-color: #b5baba;
  outline: 1px solid #979899;
  border-radius: 50px;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 2px rgba(213, 211, 211, 0.2);
}

.no-scroll {
  overflow: hidden;
  width: 100%;
  height: 100%;
  display: block;
  position: fixed;
  top: 0;
  left: 0;

  background-color: rgba(69, 66, 66, 0.4);
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
  /* touch-action: none;
    pointer-events: none; */
}

.blog-content ul {
  list-style: disc;
  list-style-position: outside;
  margin-bottom: 20px;
}
.blog-content p {
  margin-bottom: 20px;
}
.blog-content ul li {
  margin-left: 20px;
}
.blog-content a {
  color: #003c9d;
  text-decoration: underline;
}
.fit-available-width {
  height: -webkit-fill-available;
}

.scrollTopButton {
  z-index: 39;
}

.scrollTopButton:hover path {
  fill: #ffffff;
}

.scrollTopButton:hover svg {
  fill: #003c9d;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.menu::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.menu {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

/* react calendar style starts*/
.react-calendar {
  width: 415px;
}
.react-calendar__month-view__days {
  gap: 18.8px;
}
.react-calendar button {
  height: 36px;
  max-width: 36px;
  border-radius: 4px;
  border: 1px solid #003c9d;
  color: #003c9d;
}
.react-calendar__tile--active {
  color: white !important;
  outline: #f37021;
  background: #003c9d !important;
  outline-color: #0466c8;
  outline-width: 10px;
}
.react-calendar {
  padding: 24px;
  border: 1px solid #d9d9d9;
  border-radius: 8px;
}
.react-calendar__navigation__next2-button,
.react-calendar__navigation__prev2-button {
  display: none;
}
.react-calendar__navigation {
  justify-content: space-between;
  margin-bottom: 8px;
}
.react-calendar__navigation__prev-button,
.react-calendar__navigation__next-button {
  font-size: 0;
  position: relative;
  border: none !important;
  background: #f2f2f5 !important;
  color: #81878c !important;
  width: 40px !important;
  height: 40px !important;
}
.react-calendar__navigation button {
  min-width: 40px !important;
}
.react-calendar__navigation__prev-button::before {
  content: "\2190"; /* Unicode for left arrow */
  font-size: 20px;
  text-align: center;
  position: absolute;
  top: 45%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.react-calendar__navigation__next-button::before {
  content: "\2192"; /* Unicode for left arrow */
  font-size: 20px;
  text-align: center;
  position: absolute;
  top: 45%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.react-calendar__navigation button.react-calendar__navigation__label {
  min-width: max-content !important;
  border: none;
  color: #52575c;
  text-decoration: underline;
  line-height: 35px;
  font-weight: 700;
}
.react-calendar__month-view__weekdays {
  display: flex;
  gap: 18px;
  margin-bottom: 12px;
}
.react-calendar__month-view__weekdays__weekday {
  padding: 0;
  flex: 0 0 10% !important;
  line-height: 24px;
  font-weight: 700;
  text-transform: capitalize;
  color: #a0a4a8;
}
.react-calendar__month-view__weekdays__weekday abbr:where([title]) {
  text-decoration: none;
}
.react-calendar__month-view__days__day--neighboringMonth {
  visibility: hidden;
}
.react-calendar__month-view__days__day--weekend,
.react-calendar__tile:disabled {
  background: #f2f2f5;
  border: none !important;
  color: #81878c !important;
  pointer-events: none;
}
.react-calendar__tile--now {
  background: #ffffff;
  color: #003c9d !important;
  position: relative !important;
}
.react-calendar__tile--now.react-calendar__tile--active {
  color: #ffffff !important;
  background: #003c9d !important;
}
.react-calendar__tile--active:enabled:focus {
  color: #ffffff !important;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
.react-calendar__tile--now::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0px;
  height: 0px;
  border: 5px solid transparent;
  border-top-color: #f37021;
  border-right-color: #f37021;
}
.react-calendar__tile--now:enabled:hover {
  background-color: #e6e6e6;
}
/* .react-calendar__tile--active{
    color: #FFFFFF!important;
} */
.react-calendar__year-view__months {
  gap: 10px;
}
.react-calendar__year-view__months .react-calendar__tile {
  width: 115px !important;
  max-width: 115px !important;
  padding: 20px 0;
  height: auto;
}
.react-calendar__century-view .react-calendar__century-view__decade,
.react-calendar__decade-view__years,
.react-calendar__century-view__decades {
  gap: 14px;
}
.react-calendar__tile.react-calendar__decade-view__years__year,
.react-calendar__century-view button {
  height: auto !important;
  flex: 0 0 30.3333% !important;
  max-width: unset !important;
}
/* react calendar style ends*/

@media only screen and (max-width: 768px) {
  .orangeButton,
  .whiteButton {
    font-size: 16px;
    padding: 8px 16px;
  }
}
@media only screen and (max-width: 415px) {
  .react-calendar__month-view__weekdays {
    gap: 10px;
  }
  .react-calendar {
    width: 320px;
  }
}

/* custom radio buttons */

/* The container */
.custom-radio-container {
  display: block;
  position: relative;
}

/* Hide the browser's default radio button */
.custom-radio-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  z-index: 10;
}

/************************************************ Create a custom radio button starts **************************************************/
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 24px;
  width: 24px;

  border-radius: 50%;
  z-index: 9;
  border-color: #ffffff;
  border: 2px solid #003c9d;
}

/* On mouse-over, add a grey background color */
.custom-radio-container:hover input ~ .checkmark {
  background-color: white;
}

/* When the radio button is checked, add a blue background */
.custom-radio-container input:checked ~ .checkmark {
  background-color: #003c9d;
  border: 0px;
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the indicator (dot/circle) when checked */
.custom-radio-container input:checked ~ .checkmark:after {
  display: block;
}

/* Style the indicator (dot/circle) */
.custom-radio-container .checkmark:after {
  top: 8px;
  left: 8px;
  /* transform: translate(-50%, -50%); */
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: white;
}
.custom-radio-container label,
.custom-radio-container .label {
  margin-left: 32px;
}

.custom-checkbox-container input {
  accent-color: #003c9d;
  border-radius: 4px;
}
.custom-checkbox-container input:checked {
  accent-color: #003c9d;
  border-radius: 4px;
}

/************************************************ Create a custom radio button ends **************************************************/

/* ------------------------------------------------- react tagsinput custom styling ---------------------------------------------------*/
.react-tagsinput {
  border: 1px solid #989898; /* border with color #989898 */
  border-radius: 5px; /* rounded corners */
  padding: 0.75rem; /* padding equivalent to p-3 in Tailwind */
  resize: none; /* prevent resizing (like resize-none in Tailwind) */
  height: calc(6 * 1.5rem);
}
.react-tagsinput-tag {
  background-color: #f37021;
  color: #ffffff;
  border-radius: 4px;
}
select {
  -webkit-appearance: none;
  appearance: none;
}
select {
  background-image: url("/src/assets/images/dowmArrow.svg");
  background-size: 12px;
  background-repeat: no-repeat;
  background-position: calc(100% - 16px) center;
}

input[type="checkbox"],
input[type="radio"] {
  -webkit-appearance: none; /* Remove default styles */
  -moz-appearance: none;
  appearance: none;
  width: 24px; /* Adjust size as needed */
  height: 24px;
  border: 1px solid #ccc; /* Example border style */
  border-radius: 4px; /* Example border radius */
  background-color: #fff; /* Example background color */
  outline: none; /* Remove outline */
}

input[type="checkbox"]:checked::before,
input[type="radio"]:checked::before {
  content: "\2713"; /* Unicode checkmark character */
  display: block;
  text-align: center;
  line-height: 18px; /* Adjust to center vertically */
  color: #ffffff; /* Example color for checked state */
  background-color: #003c9d;
  height: 100%;
  padding-top: 2px;
}

/* Transition for shrinking */
.shrink {
  width: auto;
  transition: all 10s ease-in-out;
}

.list-alpha {
  counter-reset: list;
}
.list-alpha > li {
  list-style: none;
  position: relative;
}
.list-alpha > li:before {
  counter-increment: list;
  content: counter(list, lower-alpha) ") ";
  position: absolute;
  left: -1.4em;
}

/*  editor styles start */
.ql-container.ql-snow {
  min-height: 200px;
}

.editor-content h1 {
  font-size: 32px;
}
.editor-content h2 {
  font-size: 24px;
}
.editor-content h3 {
  font-size: 18px;
}
.editor-content h4 {
  font-size: 16px;
}
.editor-content h5 {
  font-size: 14px;
}
.editor-content h6 {
  font-size: 11px;
}
/*  editor styles end */
